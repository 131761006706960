import React from "react"
import "../styles/style.scss"
import { Navbar } from "./navbar.component"
import Footer from "./footer.component"

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
