import React from "react"
import { Link } from "gatsby"

import { BurgerIcon, CrossIcon } from "./contactIcons"

export class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false,
      currentTab: "Home",
    }
  }

  handleClose = () =>
    this.setState({ isOpened: false }, console.log(this.state))
  handleOpen = () => this.setState({ isOpened: true }, console.log(this.state))

  currentActiveTab = name => {
    console.log(name)
    this.setState({ currentTab: name })
  }

  renderLinks = (links, onClick) => {
    return links.map(link => {
      if (link.name !== "Contact") {
        return (
          <Link
            className={`navbar--desktop-item ${
              this.state.currentTab === link.name
                ? "navbar--desktop-active"
                : ""
            }`}
            key={link.to}
            to={link.to}
            title={link.name}
            onClick={() => onClick(link.name)}
          >
            {link.name}
          </Link>
        )
      } else {
        return (
          <a
            className={`navbar--desktop-item ${
              this.state.currentTab === link.name
                ? "navbar--desktop-active"
                : ""
            }`}
            key={link.to}
            href={`mailto:${link.to}`}
          >
            {link.name}
          </a>
        )
      }
    })
  }

  render() {
    const links = [
      { name: "Home", to: "/" },
      { name: "Agenda", to: "#agenda-section" },
      { name: "Contact", to: "events@pantheon.io" },
    ]
    return (
      <div className="navbar">
        <div className="container">
          <div className="logo">
            <a href="/">
              <img className="logo--image" src="./logo.png" />
            </a>
          </div>
          <div className="navbar--wrapper">
            <button
              className="navbar--mobile-menu"
              onClick={this.handleOpen}
              tabIndex={0}
            >
              <BurgerIcon />
            </button>

            <nav
              className={`navbar--mobile ${
                this.state.isOpened ? "navbar--mobile-isOpened" : null
              }`}
            >
              {this.renderLinks(links, this.currentActiveTab)}
              <button
                className="navbar--mobile-menu"
                onClick={this.handleClose}
                tabIndex={0}
              >
                <CrossIcon />
              </button>
            </nav>

            <nav className="navbar--desktop">
              {this.renderLinks(links, this.currentActiveTab)}
            </nav>
          </div>
        </div>
      </div>
    )
  }
}
