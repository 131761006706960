import React from "react"

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="footer--content">
          <div className="footer--logo">
            <a href="/">
              <img
                src="./footer-logo.png"
                alt="Footer Logo"
                class="footer--logo-image"
              />
            </a>
          </div>

          <div className="footer--company">
            <span className="footer--heading">Company</span>
            <div className="footer--links">
              <a
                href="https://pantheon.io/company"
                className="footer--links-item"
              >
                About
              </a>
              <a href="https://pantheon.io/blog" className="footer--links-item">
                Blog
              </a>
              <a
                href="https://pantheon.io/resources"
                className="footer--links-item"
              >
                Reources
              </a>
              <a
                href="https://pantheon.io/careers"
                className="footer--links-item"
              >
                Career
              </a>
              <a
                href="https://pantheon.io/pantheon-customer-success-stories"
                className="footer--links-item"
              >
                Customer
              </a>
              <a
                href="https://pantheon.io/company/customer-reviews"
                className="footer--links-item"
              >
                Reviews
              </a>
            </div>
          </div>

          <div className="footer--social">
            <span className="footer--heading">Social</span>
            <div className="footer--links">
              <a
                href="https://www.facebook.com/getpantheon"
                target="_blank"
                className="footer--links-item"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com/getpantheon"
                target="_blank"
                className="footer--links-item"
              >
                Twitter
              </a>
              <a
                href="https://www.linkedin.com/company/pantheon-systems-inc"
                target="_blank"
                className="footer--links-item"
              >
                LinkedIn
              </a>
              <a
                href="hhttps://www.youtube.com/user/GetPantheon"
                target="_blank"
                className="footer--links-item"
              >
                Youtube
              </a>
              <a
                href="https://www.instagram.com/getpantheon/"
                target="_blank"
                className="footer--links-item"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>

        <div className="footer--production">
          <div className="footer--production-logo">
            <a href="https://happily.io" target="_blank">
              <img
                className="footer--production-logoImage"
                src="./happily-logo.png"
              />
            </a>
          </div>
          <spa className="footer--production-message">A Happily Production</spa>
        </div>
      </div>
    </section>
  )
}

export default Footer
